import React from "react"
import { BackgroundLayout } from "layouts"
import { Forms } from "components"
import bg from "../../images/milford-sound-mitre-peak-fiordland-national-park-south-island-new-zealand.png"

const Login = () => {
  const backgroundLayoutProps: IBackgroundLayoutProps = {
    background: bg,
    backgroundName: "Fiordland National Park - New Zealand",
  }

  return (
    <BackgroundLayout {...backgroundLayoutProps}>
      <Forms.Login />
    </BackgroundLayout>
  )
}

export default Login
